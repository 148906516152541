import React, {Suspense} from 'react'
import PropTypes from 'prop-types'
import {
  Divider,
  Flex,
  Tab,
  TabPanel,
  TabList,
  TabPanels,
  Tabs,
  Text,
  useBreakpointValue,
} from '@chakra-ui/react'
import {useTranslation} from 'react-i18next'
import SupplierLayout from '../../layouts/SupplierLayout'
import {AuthProvider} from '../../contexts/authentication'
import withProviders from '../../contexts/withProviders'
import TabContentLoading from './Tabs/LoadingState'
import SmallScreenTabMenu from './SmallScreenTabMenu'
import useTabs from './useTabs'

const SettingsScreen = withProviders(({authenticityToken, settingsUrls}) => {
  const {t} = useTranslation('settings')

  const {tabs, tabIndex, changeTabs, currentTabObject} = useTabs({settingsUrls})

  const mobileView = useBreakpointValue({
    base: true,
    md: false,
  })

  return (
    <AuthProvider authenticityToken={authenticityToken}>
      <SupplierLayout state="Settings">
        <Flex
          minH="100vh"
          direction="column"
          bgColor="mld.neutral.200"
          px="md"
          py="md"
          gap="sm"
        >
          <Text as="h2" textStyle="headline3" px="sm">
            {t('settings:SettingsScreen.title')}
          </Text>
          <Divider borderColor="mld.neutral.500" />
          <Tabs isLazy index={tabIndex} onChange={changeTabs}>
            <TabList mb="sm">
              {mobileView ? (
                <SmallScreenTabMenu
                  key="smallScreenMenu"
                  tabs={tabs}
                  currentTabObject={currentTabObject}
                  changeTabs={changeTabs}
                />
              ) : (
                tabs.map(({tabName}) => (
                  <Tab key={tabName}>
                    {t(`settings:SettingsScreen.tabs.${tabName}`)}
                  </Tab>
                ))
              )}
            </TabList>
            <TabPanels>
              {tabs.map(({tabName, component}) => (
                <TabPanel key={tabName} p="0">
                  <Suspense fallback={<TabContentLoading />}>
                    {component}
                  </Suspense>
                </TabPanel>
              ))}
            </TabPanels>
          </Tabs>
        </Flex>
      </SupplierLayout>
    </AuthProvider>
  )
})

SettingsScreen.propTypes = {
  authenticityToken: PropTypes.string.isRequired,
  settingsUrls: PropTypes.shape({
    general: PropTypes.string.isRequired,
    accounting: PropTypes.string.isRequired,
    banking: PropTypes.string.isRequired,
    password: PropTypes.string.isRequired,
  }).isRequired,
}

export default SettingsScreen
